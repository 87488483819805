import { GlobalEventsService } from '../services/global-events.service';
import { createInitializerQueueList } from './initializer-manager';
import { StagDetectorService } from '../services/stag-detector.service';
import { SeoService } from '../services/seo.service';
import { EnvironmentProviders, inject, Injector, provideAppInitializer } from '@angular/core';
import { DecoratorService } from '../helpers/decorator-service.service';
import { InstallAppService } from '../services/install-app.service';
import { GoogleTagManagerService } from '../services/google-tag-manager.service';
import { ThemeService } from '../services/theme.service';
import { ServiceWorkerService } from '../services/service-worker.service';
import { RedirectorService } from '../services/redirector.service';
import { ZendeskChatService } from '../services/zendesk/zendesk-chat.service';
import { UserPaymentModeService } from '../services/user-payment-mode.service';
import { UserPhoneService } from '../services/user/user-phone.service';

/**
 * The `initApp` function initializes the DecoratorService with the provided Injector.
 */
export function initApp(injector: Injector) {
  DecoratorService.injector = injector;
}

export const provideInitializer = () => {
  return [
    provideAppInitializer(() => {
      const injector = inject(Injector);
      initApp(injector);
      return Promise.resolve();
    }),
    ...parallelInitializer,
  ];
};

const parallelInitializer: EnvironmentProviders[] = createInitializerQueueList([
  {
    token: ThemeService,
    initializerFn: (service: ThemeService) => service.resolveTheme$(),
  },
  {
    token: StagDetectorService,
    initializerFn: (service: StagDetectorService) => service.detectParams(),
  },
  {
    token: GlobalEventsService,
    initializerFn: (service: GlobalEventsService) => service.initGlobalEvent(),
  },
  {
    token: GoogleTagManagerService,
    initializerFn: (service: GoogleTagManagerService) => service.addGtmToDom(),
  },
  {
    token: ServiceWorkerService,
    initializerFn: (service: ServiceWorkerService) => service.initPWA(),
  },
  {
    token: RedirectorService,
    initializerFn: (service: RedirectorService) => service.performInitialRedirection(),
  },
  {
    token: SeoService,
    initializerFn: (service: SeoService) => service.updateGlobalHeadLink(),
  },
  {
    token: InstallAppService,
    initializerFn: (service: InstallAppService) => service.resolveShowVariables(),
  },
  {
    token: ZendeskChatService,
    initializerFn: (service: ZendeskChatService) => service.initChat(),
  },
  {
    token: UserPaymentModeService,
    initializerFn: (service: UserPaymentModeService) => service.resolveModeFromUrl(),
  },
  {
    token: UserPhoneService,
    initializerFn: (service: UserPhoneService) => service.updatePhoneStoreMask(),
  },
]);
